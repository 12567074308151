<template>
  <div>
    <v-row class="mt-5">
      <v-col cols="12">

        <v-row v-if="currentStep === STEPS.QUANTITY">
          <v-col cols="12">
            <div class="d-flex">
              <v-btn class="btn-invisible mr-5" @click.stop="showModal('empiecement-doudoune')">
                <v-icon class="mx-3" color="black darken-2" dark small>
                  mdi-information
                </v-icon>
              </v-btn>
              <p>
                La cloison abîmée est recouverte de tissu.<br/>
                Cette réparation premium est invisible lorsque nous avons le même tissu.<br/>
                Sinon, elle reste discrète et en harmonie avec votre vêtement.
              </p>
            </div>
          </v-col>
          <v-col v-for="(option, index) in options" :key="option.value" cols="12">
            <v-row>
              <v-col class="text-center" cols="7" offset-md="2">{{ option.label }}</v-col>
              <v-col class="text-center" cols="5" md="3">
                <plus-minus-field
                  :index="index"
                  :price="option.price"
                  :value="detailQte[index]"
                  @input="e => getInfos(e, option.bonus)"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-checkbox
              v-model="plumUp"
              :label="quantity > 1 ? 'Ces cloisons doivent être remplumées' : 'Cette cloison doit être remplumée'"
              @click="getPrice"
            />
          </v-col>
        </v-row>

        <v-row v-if="currentStep === STEPS.WITH_PUSH">
          <v-col cols="12">
            <p>
              Nous pouvons utiliser votre pochon de rangement pour réparer votre doudoune.<br/>
              Il se peut qu'il y ait une nuance de couleur dû à l'usage.<br/>
              Attention, veillez à ce que les dimensions du pochon puissent couvrir la cloison.
            </p>
          </v-col>
          <v-col class="text-center" cols="12" md="9" offset-md="3">
            <v-radio-group v-model="withPouch">
              <v-radio :value="true" label="Je fournis le pochon"/>
              <v-radio :value="false" label="Je ne fournis pas la matière"/>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row v-if="currentStep === STEPS.WITH_CALL">
          <v-col cols="12" md="9" offset-md="3">
            <h4>Choisissez votre solution</h4>
            <v-radio-group v-model="withCall">
              <v-radio :value="false" label="Coloris approchant"/>
              <v-radio :value="true" label="Recherche de solution approfondie"/>
            </v-radio-group>
          </v-col>
          <v-col v-if="!withCall" cols="12">
            Nous utilisons le tissu ayant le coloris le plus proche de votre doudoune (ou identique si nous avons).<br/>
            Nous avons plusieurs tissus JOTT (collections 2022 à 2024) ainsi qu'un stock de tissus classiques (bleus,
            noirs, mats, brillants...).
          </v-col>
          <v-col v-if="withCall" cols="12">
            À réception, nous vous contactons pour trouver ensemble la solution la plus adaptée.<br/>
            Lors de l'appel, nous déterminons ensemble la solution parmi plusieurs (extraction de matière, couture
            simple, symétrie par empiècement...).<br/>
            Des frais complémentaires jusqu'à 40€ peuvent s'appliquer en fonction de votre choix.
          </v-col>
        </v-row>

        <v-row v-if="currentStep === STEPS.WITH_PRESSING">
          <v-col cols="12">
            <h4>Souhaitez-vous ajouter un nettoyage ?</h4>
            <p>
              Votre doudoune peut être nettoyée dans notre pressing partenaire après réparation.<br/>
              Cela permet de redonner du gonflant à la doudoune.
            </p>
            <v-radio-group v-model="withPressing">
              <v-radio :value="1" label="Oui, ajouter le nettoyage au pressing"/>
              <v-radio :value="0" label="Non merci"/>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row v-if="currentStep === STEPS.CONFIRM">
          <v-col cols="12">
            <v-textarea
              v-model="emplacement"
              label="Emplacement"
              name="input-7-4"
              outlined
              placeholder="Indiquez l'emplacement"
            />
            <image-preview
              :is-bonus-eligible="bonus > 0"
              :value="pictures"
              @change="v => pictures = v"
            />
          </v-col>
        </v-row>

      </v-col>
      <v-col class="d-flex align-content-space-between justify-content-between" cols="12">
        <div>
          <div v-if="currentStep > STEPS.QUANTITY" class="btn btn-light" @click="setPreviousStep">
            PRÉCÉDENT
          </div>
        </div>
        <div
          v-if="currentStep < STEPS.CONFIRM"
          :class="{disabled: quantity === 0}"
          class="btn btn-1"
          @click="setNextStep"
        >
          SUIVANT
        </div>
      </v-col>
      <price :amount="price" :bonus="bonus" :display-bottom-line="price > 0 && currentStep === STEPS.CONFIRM"/>
    </v-row>
    <bonus-image-confirm
      v-if="bonus > 0 && pictures.length < 2 && currentStep === STEPS.CONFIRM"
      @skip-bonus="bonus = 0"
    />
    <confirm-flow
      v-else-if="price > 0 && currentStep === STEPS.CONFIRM"
      @finalize="noarticle"
      @new-repair="onSelectNewRepair"
      @new-article="noarticle(false)"
    />
  </div>
</template>
<script>
import {mapMutations, mapState} from 'vuex'
import Price from "@/components/shared/Price.vue";
import BonusImageConfirm from "@/components/shared/BonusImageConfirm.vue";
import ConfirmFlow from "@/components/shared/ConfirmFlow.vue";
import PlusMinusField from "@/components/interface/PlusMinusField.vue";
import ImagePreview from "@/components/interface/ImagePreview.vue";

let liste_reparations = []

const STEPS = {
  QUANTITY: 1,
  WITH_PUSH: 2,
  WITH_CALL: 3,
  WITH_PRESSING: 4,
  CONFIRM: 5
}

export default {
  data() {
    return {
      options: [
        {
          label: "Nombre de cloisons simples (devant ou dos du vêtement)",
          labelApi: "cloisons simples",
          value: "simple",
          price: 32,
          bonus: 7
        },
        {
          label: "Nombre de cloisons complexes (manche, capuche, près d'une poche, intérieur vêtement) ",
          labelApi: "cloisons complexes",
          value: "complexe",
          price: 42,
          bonus: 7
        }
      ],

      currentStep: STEPS.QUANTITY,
      STEPS,

      quantity: 0,
      plumUp: false,
      withPouch: false,
      withCall: false,
      withPressing: 0,

      plumUpPrice: 5,
      pouchPrice: -3,
      callPrice: 5,
      pressingPrice: 35,

      price: 0,
      bonus: 0,
      detailQte: [],
      detailPrice: [],
      detailBonus: [],
      emplacement: null,
      reduction: 0,
      pictures: [],
      error: false,
      precision: false,
    }
  },
  computed: {
    ...mapState('cart-app', ['cards', 'products']),
    ...mapState('flux-app', ['flux']),
  },
  watch: {
    withPouch() {
      this.getPrice()
    },
    withCall() {
      this.getPrice()
    },
    withPressing() {
      this.getPrice()
    }
  },
  methods: {
    ...mapMutations('flux-app', ['setConstat', 'setcptProduct', 'setcptReparation', 'setVerrou', 'setActiveTab', 'setLevel1', 'setLevel2', 'setLevel3', 'setComponentName']),
    ...mapMutations('cart-app', ['addProduct', 'getTotalCart']),
    getInfos(value, bonus) {
      this.quantity = 0
      this.$set(this.detailQte, value[0], value[1])
      this.$set(this.detailPrice, value[0], value[2])
      this.$set(this.detailBonus, value[0], (bonus ?? 0) * value[1])
      for (let i = 0; i < this.detailQte.length; i++) {
        this.quantity += this.detailQte[i] ?? 0
      }

      this.getPrice()
    },
    noarticle(showCart = true) {
      if (this.price <= 0) {
        return
      }

      this.storeProduct()
      this.setcptProduct(this.flux.cptProduct + 1)
      liste_reparations = []
      this.setcptReparation(0)


      this.$emit('evenement', 'scroll1')

      if (showCart) {
        this.setConstat('cart')
        this.$emit('showmodal', 'cart')
      }
    },
    onSelectNewRepair() {
      this.storeProduct()
      this.$emit('evenement', 'scroll3')
    },
    editProduct() {
      liste_reparations = this.products[this.flux.indexEditProduit].reparations
      //On check l'historique des réparations
      let index = liste_reparations.findIndex((obj => obj.id == (this.flux.indexEditReparation + 1)));
      if (index > -1) {
        let reparation = {
          id: liste_reparations[index].id,
          type: 'Empiècement',
          componentName: this.flux.level3,
          activeTab: 'empiecement-doudoune',
          emplacement: this.emplacement,
          precision: this.precision,
          qte: 1,
          price: this.price,
          bonus: this.pictures.length > 1 ? this.bonus : 0,
          pictures: this.pictures,
          details: [
            this.detailQte,
            this.detailPrice
          ],
          elements: [{labels: this.options.map(label => label.labelApi)}]
        }

        this.products[this.flux.indexEditProduit].reparations.splice(index, 1, reparation)
        let data = {
          idLevel1: this.products[this.flux.indexEditProduit].idLevel1,
          level1: this.products[this.flux.indexEditProduit].level1,
          level2: this.products[this.flux.indexEditProduit].level2,
          level3: this.products[this.flux.indexEditProduit].level3,
          isDouble: this.products[this.flux.indexEditProduit].isDouble,
          id: this.products[this.flux.indexEditProduit].id,
          name: this.products[this.flux.indexEditProduit].name,
          famille: this.products[this.flux.indexEditProduit].famille,
          reparations: liste_reparations
        }

        this.addProduct(data)
        this.getTotalCart(this.products)
        this.$emit('evenement', 'scroll3')

      }
    },
    storeProduct() {
      if (this.price <= 0) {
        return
      }
      this.setcptReparation(this.flux.cptReparation + 1)

      if (this.flux.cptProduct < 1) {
        this.setcptProduct(this.flux.cptProduct + 1)
      }

      //On check l'historique des réparations
      liste_reparations = []
      let index = this.products.findIndex((obj => obj.id == this.flux.cptProduct));
      if (index > -1) {
        liste_reparations = this.products[index].reparations
      }

      const emplacement = [
        this.emplacement,
        this.plumUp ? `Remplumer ${this.quantity > 1 ? 'les cloisons' : 'la cloison'}` : null,
        this.withPouch ? 'Pochon fournis' : (this.withCall ? 'Être rappelé pour une recherche de coloris / solutions' : 'Coloris en stock'),
      ].filter(e => !!e).join('<br />')

      const price = this.withPressing ? this.price - this.pressingPrice : this.price

      liste_reparations.push({
        id: this.flux.cptReparation,
        type: 'Empiècement',
        componentName: this.flux.level3,
        activeTab: 'empiecement-doudoune',
        emplacement,
        precision: this.precision,
        qte: 1,
        price,
        bonus: this.pictures.length > 1 ? this.bonus : 0,
        pictures: this.pictures,
        details: [
          this.detailQte,
          this.detailPrice
        ],
        elements: [{labels: this.options.map(label => label.labelApi)}]
      })

      if (this.withPressing) {
        this.setcptReparation(this.flux.cptReparation + 1)

        liste_reparations.push({
          id: this.flux.cptReparation,
          type: 'Nettoyage au pressing',
          componentName: 'pressing',
          activeTab: 'pressing',
          qte: 1,
          price: this.pressingPrice
        })
      }

      this.addProduct({
        idLevel1: this.flux.idLevel1,
        level1: this.flux.level1,
        level2: this.flux.level2,
        level3: this.flux.level3,
        isDouble: this.flux.isDouble,
        id: this.flux.cptProduct,
        name: this.flux.level2,
        famille: this.flux.level1,
        reparations: liste_reparations
      })
      this.setLevel3(null)
      this.setComponentName(null)
      this.getTotalCart(this.products)
    },
    showModal(constat) {
      this.setConstat(constat)
      this.$emit('showmodal')
    },
    getPrice() {
      this.price = 0
      this.bonus = 0
      for (let i = 0; i < this.detailPrice.length; i++) {
        this.price += (this.detailPrice[i] ?? 0)
        this.bonus += (this.detailBonus[i] ?? 0)
      }

      if (this.plumUp) {
        this.price += (this.plumUpPrice * this.quantity)
      }

      if (this.price > 0 && this.withPouch) {
        this.price += this.pouchPrice
      }

      if (this.withCall) {
        this.price += this.callPrice
      }

      if (this.withPressing) {
        this.price += this.pressingPrice
      }
    },
    setPreviousStep() {
      if (this.currentStep === STEPS.WITH_PRESSING && this.withPouch) {
        this.currentStep -= 2
        return
      }

      this.currentStep--
    },
    setNextStep() {
      if (this.currentStep === STEPS.QUANTITY && this.quantity < 1) {
        return
      }

      if (this.currentStep === STEPS.WITH_PUSH && this.withPouch) {
        this.currentStep += 2
        return
      }

      this.currentStep++
    }
  },
  components: {
    ImagePreview,
    PlusMinusField,
    ConfirmFlow,
    BonusImageConfirm,
    Price
  }
}
</script>
